
<template>
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统监管</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a"
            >人脸认证预警</a
          >
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">查看认证照片</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="topCss flexdc" style="align-items: flex-start">
          <div class="rzmb_1">
            <img :src="topData.headPath" alt="" />
          </div>
          <div class="rzmb_2">
            <p>认证模板</p>
            <p>班级名称：{{ topData.projectName }}</p>
            <p>班级编码：{{ topData.projectCode }}</p>
            <p>开班机构：{{ topData.compName }}</p>
            <p>姓名：{{ topData.userName }}</p>
            <p>身份证号：{{ topData.idcard }}</p>
            <p>手机号：{{ topData.mobile }}</p>
          </div>
          <div class="rzmb_3">
            <el-button
              v-if="topData.lastButtonShow"
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="upAndDown('up')"
              >上一个</el-button
            >
            <el-button
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="handleWarning"
              >处理</el-button
            >
            <el-button
              v-if="topData.nextButtonShow"
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="upAndDown('down')"
              >下一个</el-button
            >
            <el-button
             
              type="primary"
              size="small"
              class="bgc-bv"
              round
              @click="MessageReminder()"
              >消息提醒</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-image
              class="demo-image__preview"
              v-for="(item, index) in listData"
              :key="index"
              style=""
              :src="item.approveFace"
              @click="seeFailedPhoto(item)"
            >
            </el-image>
          </div>
        </div>
        <el-pagination
          :total="fenYe.total"
          :page-size="fenYe.size"
          :current-page="fenYe.currentPage"
          background
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="total, sizes,prev, pager, next,jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <!-- 查看失败照片相亲 -->
    <el-dialog
      title="失败照片详情"
      :visible.sync="failedPhotoLoading"
      top="5%"
      width="490px"
    >
      <p>课件名称：{{ failedPhotoInfo.catalogName }}</p>
      <p>认证失败时间：{{ failedPhotoInfo.createTime }}</p>
      <img
        style="width: 450px; height: 450px;margin-top:20px;"
        :src="failedPhotoInfo.approveFace"
        alt=""
      />
    </el-dialog>
      <!-- 消息提醒 - loading -->
    <el-dialog
        title="消息提醒"
        :visible.sync="dialogTemplate"
        width="35%"
        class="toVoidLoding"
        @close="resetForm('ruleForm')"
        >
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="提醒模板" prop="docType">
                <el-radio-group v-model="ruleForm.docType" @change="handleChange">
                    <el-radio label="1">换认证模板</el-radio>
                    <el-radio label="2">作弊违规提醒</el-radio>
                    <el-radio label="3">其他</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="提醒内容" prop="noticeContent" class="noticeContent">
                <el-input type="textarea" v-model="ruleForm.noticeContent" maxlength="70" show-word-limit ></el-input>
            </el-form-item>
            <el-form-item>
                
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取消</el-button>
            <el-button class="bgc-bv" @click="submitForm('ruleForm')">确定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>

import List from "@/mixins/List";
export default {
  name: "operate/faceAuthenticationWarning",
  components: {
  },
  mixins: [List],
  data() {
    return {
      // 上个页面传过来的参数
      pageData: {},
      // 上半部分数据
      topData: {},
      // 列表数据
      listData: [],
      // 预览照片用的数组
      urlData: [],
      // 分页
      fenYe: {
        currentPage: 1, // 当前页数
        size: 30,
        total: null, // 共多少页
      },
      // 失败照片loading - 弹框
      failedPhotoLoading: false,
      // 失败弹框显示的信息
      failedPhotoInfo: {
        approveFace: "", // 失败照片url
        catalogName: "", // 课件名称
        createTime: "", // 失败时间
      },
      dialogTemplate:false, //提醒模板 显示
      ruleForm:{
        docType:'3',
        noticeContent:'',
      },
      rules:{
        noticeContent:[{required:true, message:'请输入提醒内容', trigger:"blur"}]
      }
    };
  },
  created() {
    this.pageData = this.$route.query;
    this.initFunction();
  },
  mounted() {},
  methods: {
    // 处理请求的先后顺序
    async initFunction() {
      await this.getStudentData();
      this.getListData();
    },
    // 获取学员基本信息
    getStudentData(UserIndex, pageNum) {
      let params = {
        pageNum: pageNum || this.pageData.pageNum,
        pageSize: this.pageData.pageSize,
        userIndex: UserIndex == 0 ? 0 : UserIndex || this.pageData.userIndex,
      };
      if (this.pageData.areaId) {
        params.areaId = this.pageData.areaId;
      }
      if (this.pageData.userName) {
        params.userName = this.pageData.userName;
      }
      if (this.pageData.idcard) {
        params.idcard = this.pageData.idcard;
      }
      if (this.pageData.mobile) {
        params.mobile = this.pageData.mobile;
      }
      if (this.pageData.courseName) {
        params.courseName = this.pageData.courseName;
      }
      if (this.pageData.handleStatus) {
        params.handleStatus = this.pageData.handleStatus;
      }
      if (this.pageData.projectName) {
        params.projectName = this.pageData.projectName;
      }
      if (this.pageData.projectCode) {
        params.projectCode = this.pageData.projectCode;
      }
      if (this.pageData.areaId) {
        params.areaId = this.pageData.areaId;
      }
      if (this.pageData.compId) {
        params.compId = this.pageData.compId;
      }
      console.log(params)
      return this.$post("/biz/userface/warning/pageList/indexInfo", params)
        .then((res) => {
          this.topData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取列表数据(当前页数，每页条数，是否是点击上一个下一个)
    getListData(page = 1, pagesize = this.fenYe.size) {
      this.fenYe.currentPage = page == 1 ? 1 : this.fenYe.currentPage;
      const params = {
        projectCourseId: this.topData.projectCourseId,
        projectId: this.topData.projectId,
        userId: this.topData.userId,
        pageNum: page,
        pageSize: pagesize,
      };
      this.$post("/biz/userface/fail/pageList", params)
        .then((res) => {
          for (let index = 0; index < res.data.list.length; index++) {
            res.data.list[index].select = false;
            this.urlData.push(res.data.list[index].approveFace);
          }
          this.listData = res.data.list || [];
          this.fenYe.total = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 每页多少条
    handleSizeChange(val) {
      this.fenYe.size = val;
      this.fenYe.currentPage = 1;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.fenYe.currentPage = val;
      this.getListData(this.fenYe.currentPage, this.fenYe.size);
    },
    // 点击下一个/上一个
    async upAndDown(upAndDownType) {
      // 上一个
      if (upAndDownType == "up") {
        // 获取 -下一个学员基本信息
        await this.getStudentData(
          this.topData.lastUserIndex,
          this.topData.lastPageNum
        );
        // 获取 -下一个列表数据
        this.getListData(1, this.fenYe.size);
      }
      // 下一个
      if (upAndDownType == "down") {
        // 获取 -下一个学员基本信息
        await this.getStudentData(
          this.topData.nextUserIndex,
          this.topData.nextPageNum
        );
        // 获取 -下一个列表数据
        this.getListData(1, this.fenYe.size);
      }
    },
    // 处理
    handleWarning() {
      this.$post("/biz/userface/warning/handle", {
        warningId: this.topData.warningId,
      }).then((res) => {
        if (res.status == "0") {
          this.$message.success(res.message);
        }
      });
    },
    // 查看失败照片 - 弹框
    seeFailedPhoto(item) {
      this.failedPhotoLoading = true;
      this.failedPhotoInfo.approveFace = item.approveFace;
      this.failedPhotoInfo.catalogName = item.catalogName;
      this.failedPhotoInfo.createTime = item.createTime;
    },
    //消息提醒
    MessageReminder() {
      this.dialogTemplate = true;
     
    },
    //提示模板选择
    handleChange(val) {
      this.$refs.ruleForm.validateField('noticeContent');
      if(val == '1') {
        this.ruleForm.noticeContent = this.topData.userName + '学员您好，系统检测到您的认证照片存在异常，请在我的－个人信息－人脸认证处检查照片并重新认证。'
        this.$refs.ruleForm.validateField('noticeContent');
      } else if(val== '2') {
        this.ruleForm.noticeContent = this.topData.userName + '学员您好，系统检测到您在学习过程中存在违规情况（使用照片认证、使用加速器播放等），请确保为本人学习，以免影响学习结果。'
        this.$refs.ruleForm.validateField('noticeContent');
      } else {
        this.ruleForm.noticeContent = ''
      }
    },
    //确认
     submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
             this.$post('/biz/userface/warning/notice',{warningId:this.topData.warningId,noticeContent:this.ruleForm.noticeContent}).then(res => {
        if(res.status == "0") {
          this.$message.success(res.message);
          this.dialogTemplate = false;
        }
      })
          } else {
            return false;
          }
        });
      },
      //取消
      resetForm(formName) {
         this.$refs[formName].resetFields();
          this.dialogTemplate = false;
      }
  },
};
</script>

<style lang="less" scoped>
.topCss {
  padding-bottom: 0.675rem;
  border-bottom: 1px solid #f5f5f6;
  box-sizing: border-box;
  display: flex;
  overflow: hidden;
  align-items: center;
  overflow: initial;
  flex-direction: row;
  .rzmb_1 {
    width: 160px;
    height: 190px;
    margin-right: 10px;
    p {
      text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
  .rzmb_2 {
    width: 600px;
    height: 150px;
    margin-right: 60px;
    p {
      line-height: 24px;
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
  .rzmb_3 {
    padding-top: 155px;
    height: 150px;
  }
}
.demo-image__preview {
  width: 120px;
  height: 120px;
  float: left;
  margin-right: 8px;
  margin-bottom: 6px;
}
.noticeContent {
  .el-textarea /deep/.el-textarea__inner {
  min-height: 100px !important;
  resize: none !important;
}
}

</style>
